.anchor-text, .anchor-text:visited {
    color: $color-2;
    transition: color 0.2s ease-in;

    &:hover, &:active {
      color: lighten($color-2, 25%);
    }
}

.anchor-text--light, .anchor-text--light:visited {
    color: $grey-1;
    transition: color 0.2s ease-in;

    &:hover, &:active {
      color: rgba($grey-1, .5);
    }
}

.anchor-text--dark, .anchor-text--dark:visited {
    color: $color-1;
    transition: color 0.2s ease-in;

    &:hover, &:active {
      color: rgba($color-1, .5);
    }
}

.delete-btn {
    color: $color-delete;
    transition: color 0.2s ease-in;
    cursor: pointer;
    margin: .5rem;
    
    &:hover, &:active {
        color: lighten($color-delete, 25%);
    }
} 

.btn {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    font-family: inherit;
    padding: 0.85em 1em;
    -webkit-appearance: none;
    border-radius: 100px;
    font-size: 1rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    font-weight: 400;

    &--hollow {
        color: $color-2;
        border: 1px solid $color-2;
        transition: color 0.2s ease-in, border-color 0.2s ease-in;

        &:hover {
            color: lighten($color-2, 25%);
            border-color: lighten($color-2, 25%);
        }
    }

    &--hollow--light {
        color: $grey-1;
        border: 1px solid $grey-1;
        transition: color 0.2s ease-in, border-color 0.2s ease-in;
    
        &:hover {
            color: rgba($grey-1, .5);
            border-color: rgba($grey-1, .5);
        }
    }

    &-cta {
        color: $black;
        border: 1px solid $color-sec-2;
        background-color: $color-sec-2;
        transition: background-color 0.2s ease-in, color 0.2s ease-in;

        &:hover {
            color: $black;
            background-color: lighten($color-sec-2, 5%);
            border: 1px solid lighten($color-sec-2, 5%);
        }
    }

    @include breakpoint(medium) {
        &--small {
            min-width: 10rem;
        }
    
        &--medium {
            min-width: 12rem;
        }
    
        &--large {
            min-width: 15rem;
        }
    }
}

[type="submit"].btn-submit,
[type="button"].btn-submit,
[type="submit"].btn-apply, 
[type="button"].btn-apply, 
[type="submit"].btn-hollow, 
[type="button"].btn-hollow,
[type="submit"].btn-cta,
[type="button"].btn-cta {
    border-radius: 100px!important;
    min-width: 8rem;
    
    @include breakpoint(medium) {
        min-width: 10rem;
    }
}
    
[type="submit"].btn-apply,
[type="button"].btn-apply {
    background-color: $color-sec-2;
    border: none;
    transition: background-color 0.2s ease-in;
    
    &:hover {
        background-color: lighten($color-sec-2, 5%);
    }

    &--secondary {
        background-color: $color-1;
        color: $grey-1;
        transition: background-color 0.2s ease-in;

        &:hover {
            background-color: lighten($color-1, 10%);
        }
    }
}


[type="submit"].btn-calc,
[type="button"].btn-calc {
    border-radius: 100px!important;
    border-style: solid;
    border-width: 1px;
    color: $black;
    min-width: 8rem;
    background-color: $white;
    transition: color 0.2s ease-in, background-color 0.2s ease-in;
    
    &:hover {
        color: $white;
        background-color: $color-sec-1;
    }

    @include breakpoint(medium) {
        min-width: 10rem;
    }
}

[type="submit"].btn-calc-highlighted,
[type="button"].btn-calc-highlighted {
    border-color: $color-sec-1;
    background-color: $color-sec-1;
    color: $white;
    transition: color 0.2s ease-in, background-color 0.2s ease-in;

    &:hover {
        background-color: $white;
        color: $black;
    }
}

[type="text"]:disabled,
[type="button"]:disabled,
[type="submit"]:disabled {
    border: none;
    background-color: $grey-3;
    color: $grey-dark;
    cursor: default;
    
    &:hover {
        background-color: $grey-3;
        color: $grey-dark;
    }
}

// remove outline
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
  
[contenteditable="true"]:focus {
  outline: none;
} 