// WIZARD START 
$wizard-circular-color: $color-sec-2;
$wizard-circular-border-thickness: 2px;
$wizard-list-item-color: $grey-1;

.wizard {

  @include breakpoint(large) {
    margin-top: 1.5rem;
  }

  li {
    display: inline-block;
    color: $wizard-list-item-color;
    width: 2.2rem;
    font-size: .875rem;

    @include breakpoint(medium) {
      width: 2.7rem;
      font-size: 1.125rem;
    }

    &.wizard__step {

      opacity: .5;

      &.current {
        border: $wizard-circular-border-thickness solid $wizard-circular-color;
        border-radius: 500px;
        padding: 0.285em 0.8em;
        opacity: 1;
      }

      &.active {
        opacity: 1;
        font-weight: 700;
      }

      &.current a, &.active a {
        color: white;
      }
    }
  }
}
// WIZARD END