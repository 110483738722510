// Colors

$color-1: #002E4F;
$color-2: #006494;
$color-3: #5D9EC6;

$color-sec-1: #81C3D7;
$color-sec-2: #F2994A;

$color-highlight-1: #3BB691;
$color-highlight-2: #E25E34;

$color-delete: #e23434;

$grey-1: #EBF2FA;
$grey-2: scale-color($grey-1, $lightness: -15%);
$grey-3: #cacaca;
$light-grey: #dde4ea;
$dark-grey-text: #333333;
$grey-dark: #5C5C5C;
$light-grey-blue: #D8E2ED;

$white: #fff;
$black: #1A1A1A;

// forms
$input-bg-color-disabled: #e6e6e6;
$input-color-disabled: #c7c7c7;

