.l-bg-color {
    background-color: $color-1;
}

.l-bg-color--w {
    background-color: $grey-1;
}

.l-bg-color--light {
    background-color: $light-grey-blue;
}