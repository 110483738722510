.icon {
    font-size: 1.5rem;
    padding-left: .3125rem;
    padding-right: .3125rem;

    &--small {
        font-size: 1rem;
    }

    &--medium {
        font-size: 1.5rem;
    }

    &--large {
        font-size: 2rem;
    }

    &--xlarge {
        font-size: 4rem;
    }

    &--xxlarge {
        font-size: 6rem;
    }

    &--light {
        color: $grey-1;
    }
  }