.hp-hero,
.hp-info,
.hp-features,
.hp-how {
  @include breakpoint(small only) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@mixin hp-section-header {
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;

  @include breakpoint(medium) {
    font-size: 1.75rem;
  }
}

@mixin hp-section-padding($hp-section-padding-s-top, $hp-section-padding-s-btm, $hp-section-padding-m-top, $hp-section-padding-m-btm) {
  padding-top: $hp-section-padding-s-top;
  padding-bottom: $hp-section-padding-s-btm;

  @include breakpoint(medium) {
    padding-top: $hp-section-padding-m-top;
    padding-bottom: $hp-section-padding-m-btm;
  }
}

.hp-hero {
  position: relative;
  padding-top: 10vh;
  padding-bottom: 10vh;
  
  @include breakpoint(medium) {
    padding-top: 15vh;
    padding-bottom: 15vh;
  }

  nav {
    width: 100%;
  }

  &__header {
    color: $grey-1;
  }
  &__subheader {
    color: $grey-1;
    font-weight: $global-weight-light;
    opacity: .7;
    margin-bottom: 2rem;
    font-size: 1rem;

    @include breakpoint(medium) {
        font-size: 1.5rem;
        margin-bottom: 3rem;
    }
  }

  &__btn {
    
    .btn {
        width: 15rem;
    } 
  }
}

.hp-info {
  @include hp-section-padding(4rem, 4rem, 6rem, 6rem);

  &__text p {
    font-weight: 400;

    @include breakpoint(medium) {
      font-size: 1.25rem;
    }

    span {
      font-weight: 700;
    }
  }
}

.hp-features {
  @include hp-section-padding(1rem, 2rem, 2rem, 4rem);

  &__header {
    @include hp-section-header;
  }

  &__subheader {
    font-size: 1rem;
    text-align: center;

    @include breakpoint(medium) {
      font-size: 1.25rem;
    }
  }

  &__card {
    padding: 1rem 1rem 1rem;
    max-width: 35rem;
  }
}

.hp-how {
  @include hp-section-padding(1rem, 2rem, 2rem, 4rem);

  &__section {
    margin-bottom: 2rem;
  }

  &__header {
    @include hp-section-header;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;

    &-nb {
      position: relative;
      margin: 0 auto;

      div {
        background-color: $color-1;
        color: $grey-1;
        width: 2.5rem;
        height: 2.5rem;
        padding-top: .4rem;
        text-align: center;
        border-radius: 100px;
        font-size: 1.25rem;
        font-weight: 700;
      }
    }

    &-desc {
      
    }
  }
}

.hp_wam_img {
  max-width: 16rem;
  @include breakpoint(medium) {
    max-width: 30rem;
  }

  &_small {
    width: 5rem;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}

.hp-to-tool {
  margin: 0 auto;
  background-color: $color-1;
  width: 12rem;
  height: 12rem;
  text-align: center;
  border-radius: 1rem;
}

a.btn-cta:focus {
  color: #0a0a0a!important;
}