/* The container */
.container {
  display: inline-block;
  position: relative;
  padding-left: 2.1875rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $color-1;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5625rem;
  width: 1.5625rem;
  background-color: $white;
  border: 1px solid $grey-3;
  transition: background-color .2s ease-in, border .2s ease-in;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: lighten($color-3, 20%);
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: $color-3;
  border: 1px solid $color-3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
  width: .5rem;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: .5rem;
  top: .375rem;
  width: .3rem;
  height: .7rem;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


// Radio control
.control {
	display: block;
	position: relative;
	padding-left: 2rem;
	margin-bottom: 1rem;
	cursor: pointer;
	font-size: 1rem;
}

.control input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.control__indicator {
	position: absolute;
	top: 2px;
	left: 0;
	height: 1.25rem;
	width: 1.25rem;
	background: $grey-3;
}

.control--radio .control__indicator {
	border-radius: 50%;
}

.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
	background: $grey-3;
}

.control input:checked ~ .control__indicator {
	background: $color-sec-1;
}

.control__indicator:after {
	content: '';
	position: absolute;
	display: none;
}

.control input:checked ~ .control__indicator:after {
	display: block;
}

.control--radio .control__indicator:after {
	left: 7px;
	top: 7px;
	height: 6px;
	width: 6px;
	border-radius: 50%;
	background: $white;
}