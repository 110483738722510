.card-thin {
  background-color: $white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: .7rem;
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, .25);
  -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, .25);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .25);
  transition: all .2s ease-in;
  backface-visibility:hidden;

  &:hover:not(.card-thin--empty) {
    -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, .25);
	  -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, .25);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, .25);
    transform: translateY(-2px);
    cursor: pointer;
  }

  @include breakpoint(medium) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1rem;
  }

  &__number {
    font-weight: 700;
  }

  img {
    position: relative;
    top: -.1875rem;
    width: 1.4rem;
  }

  &__text {
    position: relative;
    text-align: left;
    padding-right: 1rem;

    .icon {
      color: $color-1;
      position: absolute;
      right: -1rem;
      top: -.4rem;
      transition: color 0.2s ease-in;

      &:hover {
        color: lighten($color-1, 20%);
      }
    }
  }

  &--empty {
    position: relative;
    padding: 0;
    background-color: transparent;
    -webkit-box-shadow: none;
	  -moz-box-shadow: none;
    box-shadow: none;
    border: 3px dashed $color-2;
    color: $color-2;
    transition: color 0.2s ease-in, border-color 0.2s ease-in;

    &__text {
      line-height: 3.5rem;
      font-size: 1.1rem;
      font-weight: 700;
      text-align: left;

      @include breakpoint(large) {
        font-size: 1.3rem;
        line-height: 4.5rem;
      }
    }

    i {
      font-size: 2rem;
      line-height: 3.5rem;

      @include breakpoint(large) {
        font-size: 3rem;
        line-height: 4.5rem;
      }
    }

    &:hover {
      color: $color-sec-1;
      border-color: $color-sec-1;
      cursor: pointer;
    }
  }
}

/* --------------------- CARD MEDIUM SIZE --------------------- */

$card-medium-section-height: 3.5rem;
$card-medium-section-padding: .3rem;
$card-medium-icon-color: $color-2;
$card-medium-icon-hover-color: lighten($card-medium-icon-color, 15%);

.card-medium {

  .card-divider {
    background-color: $color-1;
    color: $grey-1;
    height: 6rem;
    padding-top: 1.2rem;
    font-size: 1.125rem;

    .grid-x {
      width: 100%;
    }

    img {
      height: 1.4rem;
      padding-right: .5rem;
    }
  }

  .card-section {
    height: $card-medium-section-height;
    padding-top: $card-medium-section-padding;
    padding-bottom: 0;
    text-align: left;

    .card-section__icons {
      float: right;
      width: 8rem;
      text-align: right;
      height: calc(#{$card-medium-section-height} - (#{$card-medium-section-padding} * 2));
      line-height: calc(#{$card-medium-section-height} - #{$card-medium-section-padding});

      a {
        display: inline-block;
        transition: all .2s ease-in;

        &:hover {
          transform: translateY(-3px);
        }
      }

      a i {
        color: $card-medium-icon-color;
        padding-left: 1rem;
        padding-right: .3125rem;
        transition: all .2s ease-in;

        &:hover {
          color: $card-medium-icon-hover-color;
        }
      }
    }
  }

  &--empty {
    width:100%;
    position: relative;
    padding: 0;
    background-color: transparent;
    -webkit-box-shadow: none;
	  -moz-box-shadow: none;
    box-shadow: none;
    border: 3px dashed $color-2;
    color: $color-2;
    transition: color 0.2s ease-in, border-color 0.2s ease-in;

    &__text {
      line-height: 3.5rem;
      font-size: 1.1rem;
      font-weight: 700;
      text-align: left;

      @include breakpoint(large) {
        font-size: 1.3rem;
        line-height: 4.5rem;
      }
    }

    i {
      font-size: 2rem;
      line-height: 3.5rem;

      @include breakpoint(large) {
        font-size: 3rem;
        line-height: 4.5rem;
      }
    }

    &:hover {
      color: $color-sec-1;
      border-color: $color-sec-1;
      cursor: pointer;
    }
  }
}

// Number input + minus/plus
.card-section__plus-minus {
  float: left;
  width: 7rem;
  text-align: left;

  .icon {
    padding-left: 0;
    padding-right: 0;
    color: $card-medium-icon-color;
    transition: all .2s ease-in;

    &:hover {
      color: $card-medium-icon-hover-color;
    }
  }

  .input-group-field {
    text-align: center;
    margin-top: .15rem;

    &::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      appearance: none;
    }
  }

  .input-group-button {
    margin-top: .3rem;
  }

  .button {
    padding-left: .4rem;
    padding-right: .4rem;
  }

  .button.hollow {
    border: none;
  }
}