.tabs-btn {

  ul {
    width: 100%;
    list-style: none;
    margin: 0;

    li {
      width: 100%;

      @include breakpoint(medium) {
        float: left;
        width: 50%;
      }
    }
  }

  button, button:focus {
    background-color: $white;
    color: $color-2;
    font-weight: 700;
    border: 1px solid $color-2;
    
    &:hover {
      background-color: $color-2;
      color: $white;
    }
  }
  
  .active {
    background-color: $color-2;
    color: $white;
    font-weight: 700;
  
    &:hover, &:active, &:visited, &:focus {
      background-color: $color-2;
      color: $white;
      cursor: default;
    }
  }
}
  
button[type="button"].tabs-btn--left {
  width: 100%;
  border-radius: 100px;

  @include breakpoint(medium) {
    border-radius: 100px 0 0 100px;
  }
}

button[type="button"].tabs-btn--right {
  width: 100%;
  border-radius: 99px;

  @include breakpoint(medium) {
    border-radius: 0 100px 100px 0;
  }
} 