$color-slider-costs: $color-sec-2;
$color-slider-technologies: #34AB6B;

.slider {

  border-radius: 100px;
  
  &--s {
    width: 6rem;
  }
  
  &--m {
    width: 10rem;
  }
  
  &--l {
    width: 14rem;
  }
  
  &--blue {
    background-color: rgba($color-2, .4) !important;

    .slider-fill {
      background-color: $color-2;
    }

    .slider-handle {
      border: 1px solid $color-2;
    }
  }

  &--costs {
    background-color: rgba($color-slider-costs, .4) !important;

    .slider-fill {
      background-color: $color-slider-costs;
    }

    .slider-handle {
      border: 1px solid $color-slider-costs;
    }
  }

  &--technologies {
    background-color: rgba($color-slider-technologies, .4) !important;

    .slider-fill {
      background-color: $color-slider-technologies;
    }

    .slider-handle {
      border: 1px solid $color-slider-technologies;
    }
  }
  
  .slider-handle {
    background-color: $white;
    border-radius: 100px;

    &:hover {
      background-color: $white;
    }
  }

  .slider-fill {
    border-radius: 100px 0 0 100px;
  }
}