a {
    font-weight: 700;
}

p {
    font-weight: 300;
}

select {
    color: $dark-grey-text !important;
}

.l-bg-color {
    p {
        color: $grey-1;
    }
}

.l-bg-color--w {
    p {
        color: $color-1;
    }
}