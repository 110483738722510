.form {

  &__label {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    margin-top: .5rem;
    color: $color-1;

    @include breakpoint(medium) {
      margin-top: 1rem;
    }
  }

  &__select {
    color: $color-1;
  }
}
  
input[type].input--s {
  width: 4rem;
}

input[type].input--m {
  width: 7rem;
}

input[type].input--l {
  width: 10rem;
}

/*  remove default flex on form inputs,
    otherwise the input takes 100% width */
input[type].input--s,
input[type].input--m,
input[type].input--l {
  flex: none;
}

/* remove spin buttons
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none; <- Crashes Chrome on hover
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden
}
*/

/*
input[type=number] {
  -moz-appearance:textfield;
}
*/
/* --------------------- */

.plus-minus-input .input-group-button button {
  height: 2.4375rem;
  border: none;
  padding-left: .2rem;
  padding-right: .2rem;

  &.plus-minus-input--left, &.plus-minus-input--left i {
    padding-left: 0;
  }
}

.input-group.input-disabled {
  
  input {
    background-color: $input-bg-color-disabled;
    color: $input-color-disabled;
  }

  span {
    background-color: $input-bg-color-disabled;
    color: $input-color-disabled;
  }
}

fieldset legend {
  font-weight: 700;
}