.accordion li {

  .accordion-title {
    background-color: $color-3;
    color: $grey-1;
    transition: background-color .2s ease-in;

    &:hover {
      background-color: lighten($color-3, 10%);
    }
  }

  label {
    text-align: left;
    color: $color-1;
  }

  .accordion-input {

    @include breakpoint(medium) {
      
      position: relative;

      &-box {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}