// Variables
$slider-marker-color: #9e3e6a;

.parameter-slider {

  .cell {
    padding-bottom: 2.5rem;

    &:last-child {
      padding-bottom: .5rem;
    }
  }
  
  &__label {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
  }

  .mark {
    display: block;
    position: absolute;
    top: 1.95rem;
    background: $slider-marker-color;
    padding: 1px 3px;
    margin-left: -10px;
    width: .2rem;
    height: 1rem;

    &--text {
        position: absolute;
        width: 10rem;
        text-align: center;
        top: 2.8rem;
        font-size: .875rem;
        font-weight: 700;
        color: $slider-marker-color;
        cursor: help;
    }
  }
}