footer {
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;

  &:not(.footer-hp) {
    padding-bottom: 1rem;

    @include breakpoint(large) {
        padding-bottom: 2rem;
    }
  }

  .footer-hp {

    &__logo {
      text-align: center;

      &-wam {
        height: 5rem;
        padding-top: 1rem;
        margin-right: 1.5rem;
  
        @include breakpoint(medium) {
          padding-top: 1.5rem;
        }
      }

      &-rli {
        height: 5rem;
        padding-top: 1rem;
        margin-right: 1.5rem;
  
        @include breakpoint(medium) {
          padding-top: 1.5rem;
        }
      }
    }
  }

  ul {

    margin: 0 0 .3rem;

    li {
      display:inline-block;
      margin: .2rem;
      font-size: .9rem;

      @include breakpoint(large) {
          margin: 1rem;
          font-size: 1rem;
      }
    }
  }
}